.pokemon-icon {
	display: inline-block;
	width: 40px;
	height: 30px;
}

.item-icon {
	display: inline-block;
	width: 24px;
	height: 24px;
}